import request from '@/utils/request'

// 查詢司機資訊
export function get_Driver(data) {
  // console.log(data);
  return request({
    url: '/api/driver',
    method: 'post',
    data
  })
}
//查詢司機預約資料
export function get_Driver_reservation(data) {
  // console.log(data);
  return request({
    url: '/api/reservequery',
    method: 'post',
    data
  })
}
//查詢每月司機所有預約資料
export function get_Driver_List_reservation_query(data) {
  // console.log(data);
  return request({
    url: '/api/driverlist',
    method: 'post',
    data
  })
}

//搜尋這個合作夥伴司機、日期、去程回程資料
export function get_Driver_List_Bydid_reservation_query(data) {
  // console.log(data);
  return request({
    url: '/api/driverlistbydid',
    method: 'post',
    data
  })
}

//新增及更新司機所有預約資料
export function insert_update_updatereserve(data) {
  // console.log(data);
  return request({
    url: '/api/updatereserve',
    method: 'post',
    data
  })
}

//上傳所有乘客資料
export function insert_update_uploadmembercsv(data) {
  // console.log(data);
  return request({
    url: '/api/uploadcsv',
    method: 'post',
    data
  })
}

// 取消該位成員排定司機
export function cancel_member_driver_order(data) {
  // console.log(data);
  return request({
    url: '/api/reservecartstatus',
    method: 'post',
    data
  })
}
