import request from '@/utils/request'

export function getRouterPermissionFather(query) {
    console.log("get_router_permission_father",typeof(query))
    return request({
        url: '/api/premission_father/' + query,
        method: 'get',
    })
}
export function getRouterPermissionSon(query) {
    console.log("get_router_permission", typeof (query))
    // console.log("get_question", query)
    return request({
        url: '/api/premission/' + query,
        method: 'get',
    })
}
export function insert_update_father_router(data) {
  // console.log(data);
    return request({
        url: '/api/premission_father',
        method: 'post',
        data
    })
}
export function insert_update_son_router(data) {
    // console.log(data);
    return request({
        url: '/api/premission',
        method: 'post',
        data
    })
}