var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "Title" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter.apply(null, arguments)
              }
            },
            model: {
              value: _vm.listQuery.title,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "title", $$v)
              },
              expression: "listQuery.title"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "90px" },
              attrs: { placeholder: "Imp", clearable: "" },
              model: {
                value: _vm.listQuery.importance,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "importance", $$v)
                },
                expression: "listQuery.importance"
              }
            },
            _vm._l(_vm.importanceOptions, function(item) {
              return _c("el-option", {
                key: item,
                attrs: { label: item, value: item }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: "Type", clearable: "" },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.calendarTypeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: {
                  label: item.display_name + "(" + item.key + ")",
                  value: item.key
                }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(" Search ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v(" Add ")]
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-download"
              },
              on: { click: _vm.handleDownload }
            },
            [_vm._v(" Export ")]
          ),
          _c(
            "el-checkbox",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "15px" },
              on: {
                change: function($event) {
                  _vm.tableKey = _vm.tableKey + 1
                }
              },
              model: {
                value: _vm.showReviewer,
                callback: function($$v) {
                  _vm.showReviewer = $$v
                },
                expression: "showReviewer"
              }
            },
            [_vm._v(" reviewer ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "80",
              "class-name": _vm.getSortClass("id")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.id))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "150px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("parseTime")(
                            row.timestamp,
                            "{y}-{m}-{d} {h}:{i}"
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Title", "min-width": "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.title))]
                    ),
                    _c("el-tag", [
                      _vm._v(_vm._s(_vm._f("typeFilter")(row.type)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Author", width: "110px", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.author))])]
                }
              }
            ])
          }),
          _vm.showReviewer
            ? _c("el-table-column", {
                attrs: { label: "Reviewer", width: "110px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(row.reviewer))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1536508420
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Imp", width: "80px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return _vm._l(+row.importance, function(n) {
                    return _c("svg-icon", {
                      key: n,
                      staticClass: "meta-item__icon",
                      attrs: { "icon-class": "star" }
                    })
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Readings", align: "center", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.pageviews
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            on: {
                              click: function($event) {
                                return _vm.handleFetchPv(row.pageviews)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.pageviews))]
                        )
                      : _c("span", [_vm._v("0")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Status",
              "class-name": "status-col",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm._f("statusFilter")(row.status) } },
                      [_vm._v(" " + _vm._s(row.status) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Actions",
              align: "center",
              width: "230",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(" Edit ")]
                    ),
                    row.status != "published"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.handleModifyStatus(row, "published")
                              }
                            }
                          },
                          [_vm._v(" Publish ")]
                        )
                      : _vm._e(),
                    row.status != "draft"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleModifyStatus(row, "draft")
                              }
                            }
                          },
                          [_vm._v(" Draft ")]
                        )
                      : _vm._e(),
                    row.status != "deleted"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(row, $index)
                              }
                            }
                          },
                          [_vm._v(" Delete ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "70px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Type", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Please select" },
                      model: {
                        value: _vm.temp.type,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "type", $$v)
                        },
                        expression: "temp.type"
                      }
                    },
                    _vm._l(_vm.calendarTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.display_name, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Date", prop: "timestamp" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "Please pick a date"
                    },
                    model: {
                      value: _vm.temp.timestamp,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "timestamp", $$v)
                      },
                      expression: "temp.timestamp"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Title", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.title,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "title", $$v)
                      },
                      expression: "temp.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Please select" },
                      model: {
                        value: _vm.temp.status,
                        callback: function($$v) {
                          _vm.$set(_vm.temp, "status", $$v)
                        },
                        expression: "temp.status"
                      }
                    },
                    _vm._l(_vm.statusOptions, function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Imp" } },
                [
                  _c("el-rate", {
                    staticStyle: { "margin-top": "8px" },
                    attrs: {
                      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
                      max: 3
                    },
                    model: {
                      value: _vm.temp.importance,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "importance", $$v)
                      },
                      expression: "temp.importance"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                      placeholder: "Please input"
                    },
                    model: {
                      value: _vm.temp.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "remark", $$v)
                      },
                      expression: "temp.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    }
                  }
                },
                [_vm._v(" Confirm ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogPvVisible, title: "Reading statistics" },
          on: {
            "update:visible": function($event) {
              _vm.dialogPvVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.pvData,
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "key", label: "Channel" }
              }),
              _c("el-table-column", { attrs: { prop: "pv", label: "Pv" } })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogPvVisible = false
                    }
                  }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }