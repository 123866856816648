import { getPremissionRoles, getPremission, insert_update_Premission } from '@/api/premission_manager'
import { Message } from 'element-ui'

const state = {
   premissionroleslist:[],
   premissionlist:[]
}

const mutations = {
    SET_PREMISSION_ROLES: (state, premissionroleslist) => {
        state.premissionroleslist = premissionroleslist
    },
    SET_PREMISSION: (state, premissionlist) => {
        state.premissionlist = premissionlist
    }
}

const actions = {
    // 得知目前合作夥伴角色
    getPremissionRoles({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getPremissionRoles(query).then(response => {
                commit('SET_PREMISSION_ROLES', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知目前合作夥伴權限
    getPremission({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getPremission(query).then(response => {
                commit('SET_PREMISSION', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增與修改目前合作夥伴權限
    insert_update_Premission({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_Premission(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success==true?'success':'error',
                    duration: 2500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
