import Vue from 'vue'
import _ from 'lodash'
import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import { Message, Notification } from 'element-ui'
import './styles/element-variables.scss'
import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
import zhTWLang from 'element-ui/lib/locale/lang/zh-TW'// 使用繁體中文
import FastClick from 'fastclick'
FastClick.attach(document.body)

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import { LMap, LTileLayer, LMarker, LPopup, LIcon, LTooltip } from "vue2-leaflet";
// 載入 markercluster 並啟用
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
// 載入 leaflet css
import "leaflet/dist/leaflet.css";
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

//載入歷史追蹤路徑動畫
import LMovingRotatedMarker from 'vue2-leaflet-moving-rotated-marker'
Vue.component('l-moving-rotated-marker', LMovingRotatedMarker)


// 載入 地圖全螢幕顯示
import LControlFullscreen from 'vue2-leaflet-fullscreen';

//載入google map
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_BASE_API_GOOGLE_MAP,
    libraries: "places",
  },
  installComponents: true,
});

// 載入抽獎程式
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

//websocket
import Pusher from 'pusher-js'

//載入qrcode generation
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

//載入日曆模組
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
Vue.component('FullCalendar',FullCalendar)

//載入讀取json格式
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

// 啟用各式map
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);
Vue.component("l-icon", LIcon);
Vue.component("l-tooltip", LTooltip);
Vue.component('v-marker-cluster', Vue2LeafletMarkerCluster);
Vue.component('l-control-fullscreen', LControlFullscreen);
// 啟用各式map icon
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
import { getToken } from '@/utils/auth' // get token from cookie
// iconRetinaUrl: require('@/assets/logo/threebrotherflea_preview_2x.png'),
//   iconUrl: require('@/assets/logo/threebrotherflea_preview.png'),
//     shadowUrl: require("leaflet/dist/images/marker-shadow.png")
// iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//     shadowUrl: require("leaflet/dist/images/marker-shadow.png")
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  locale: enLang // 如果使用中文
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_WEB_HASH_KEY })

Vue.config.productionTip = false
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
  async created() {
    //所有即時通知資訊
    await store.dispatch('user/getInfo')
    //管理端推播
    await this.all_notify_websocket()
    //使用端推播
    // await this.all_notify_custome_websocket()
  },
  methods: {
    async all_notify_websocket(){
      Pusher.logToConsole = true;
      console.log("pusher")
      console.log(store.getters.token)
      console.log(store.getters.id)
      // console.log(process.env.VUE_APP_BASE_API_PUSHER)
      console.log(process.env.VUE_APP_BASE_API_TRANSFER)
      let pusher = new Pusher(process.env.VUE_APP_BASE_API_PUSHER, {
        cluster: 'ap3', authEndpoint: process.env.VUE_APP_BASE_API_TRANSFER +'/api/profilebroadcast',
        auth: {
          headers: {
            Authorization: 'Bearer ' + store.getters.token
          }
        }})
      //打卡模組
      pusher.subscribe('private-clock-channel-' + store.getters.id)
      pusher.bind('clock-event', resp => {
        console.log("resp.message",resp.message)
        Message({
          message: resp.message,
          type: 'success',
          duration: 2500
        })
      })
      //團購訂單模組
      pusher.subscribe('private-order-channel-' + store.getters.id)
      await pusher.bind('order-event', resp => {
        // console.log("resp.message", resp.message.success_message)
        Notification({
          message: '有新訂單通知',
          type: 'success',
          duration: 2500,
        })
        store.dispatch('shop/getOrder',1)
      })
      //聊天模組
      pusher.subscribe('private-chat-channel-' + store.getters.id)
      await pusher.bind('chat-event', resp => {
        Message({
          message: '有新訊息通知',
          type: 'success',
          duration: 2500
        })
        store.dispatch('shop/getChatUpdate',true)
      })
    },
    // async all_notify_custome_websocket() {
    //   Pusher.logToConsole = true;
    //   console.log("pusher")
    //   console.log(store.getters.token)
    //   console.log(store.getters.id)
    //   // console.log(process.env.VUE_APP_BASE_API_PUSHER)
    //   console.log(process.env.VUE_APP_BASE_API_TRANSFER)
    //   var payload = { "account":"admin","password":"1229" };
    //   await store.dispatch('admin/getCustomeLogin', payload)
    //   console.log("1229", store.state.admin.custome_token)
    //   let pusher = new Pusher(process.env.VUE_APP_BASE_API_PUSHER, {
    //     cluster: 'ap3', authEndpoint: process.env.VUE_APP_BASE_API_TRANSFER + '/custome/profilebroadcast',
    //     auth: {
    //       headers: {
    //         Authorization: 'Bearer ' + store.state.admin.custome_token
    //       }
    //     }
    //   })
    //   //訂單模組
    //   pusher.subscribe('private-order-custome-channel-' + store.getters.id)
    //   await pusher.bind('order-custome-event', resp => {
    //     console.log("resp.message", resp.message.success_message)
    //     Message({
    //       message: resp.message.success_message,
    //       type: 'success',
    //       duration: 2500
    //     })
    //     store.dispatch('shop/getOrder', 1)
    //   })
    // },
  },
})
