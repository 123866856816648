const title = ''

const content = ``

const data = {
  title,
  content
}

export default data
