import { getPushchannel, insert_update_push_channel, getPushchannelGroup, NotBindLineaccesstokengroup } from '@/api/push_channel'
import { Message } from 'element-ui'

const state = {
    pushchannellist:[],
    pushchannelgrouplist:[]
}

const mutations = {
    SET_PUSH_CHANNEL: (state, pushchannellist) => {
        state.pushchannellist = pushchannellist
    },
    SET_PUSH_CHANNEL_GROUP: (state, pushchannelgrouplist) => {
        state.pushchannelgrouplist = pushchannelgrouplist
    }
}

const actions = {
    // 得知所有推播魔組
    getPushchannel({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getPushchannel(query).then(response => {
                commit('SET_PUSH_CHANNEL', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有推播魔組
    insert_update_push_channel({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_push_channel(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有推播群組魔組
    getPushchannelGroup({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getPushchannelGroup(query).then(response => {
                commit('SET_PUSH_CHANNEL_GROUP', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 解除綁定line notify group
    NotBindLineaccesstokengroup({ commit }, data) {
        return new Promise((resolve, reject) => {
            NotBindLineaccesstokengroup(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
