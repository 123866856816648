import request from '@/utils/request'

// 得知團購模組商品管理
export function getGroupBuyShop(query) {
  console.log('get_group_buy_shop', typeof (query))
  // console.log("get_member_list", query)
    return request({
      url: '/api/gproduct/' + query,
      method: 'get'
    })
}

// 得知團購模組商品管理單一商品
export function getGroupBuySingleShop(query,query_2) {
    console.log('get_group_buy_single_shop', typeof (query))
    // console.log("get_member_list", query)
    return request({
        url: '/api/gproduct_id/' + query+'/'+query_2,
        method: 'get'
    })
}

// 新增團購模組商品管理
export function insertgroupbuyshop(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/gproduct',
        method: 'post',
        data
    })
}

// 修改團購模組商品管理
export function editgroupbuyshop(id,data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/gproduct/'+id,
        method: 'post',
        data
    })
}

// 刪除團購模組商品管理
export function delgroupbuyshop(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/delgproduct',
        method: 'post',
        data
    })
}

// 得知團購模組開團
export function getGroupBuyGroup(query) {
    console.log('get_group_buy_group', typeof (query))
    // console.log("get_member_list", query)
    return request({
        url: '/api/groupbuy/' + query,
        method: 'get'
    })
}


// 新增團購模組開團
export function insertgroupbuygroup(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/groupbuy_info',
        method: 'post',
        data
    })
}

// 修改團購模組開團
export function editgroupbuygroup(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/groupbuy_info/' + data.id,
        method: 'post',
        data
    })
}
// 刪除團購模組開團內商品
export function delete_groupbuy_product(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/delete_groupbuy_product',
        method: 'post',
        data
    })
}

// 得知團購模組訂單管理
export function getGroupBuyOrder(query) {
    console.log('get_group_buy_order', typeof (query))
    // console.log("get_member_list", query)
    return request({
        url: '/api/gorder/' + query,
        method: 'get'
    })
}

// 新增團購模組訂單
export function insertgroupbuyorder(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/gorder',
        method: 'post',
        data
    })
}

// 修改團購模組訂單
export function editgroupbuyorder(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/gorder/' + data.orderid,
        method: 'post',
        data
    })
}

// 刪除團購模組訂單內商品
export function delete_groupbuy_order_items(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/delete_groupbuy_order',
        method: 'post',
        data
    })
}

// 恢復預設圖片
export function delGroupBuyShopImage(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/delgproductimg',
        method: 'post',
        data
    })
}

// 匯出團購模組訂單
export function downloadgroupbuyorder(query) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/orderlistbygid/' + query,
        method: 'get'
    })
}

// 統計團購模組商品數量
export function getGroupBuyStatic(query) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/groupbuysum/' + query,
        method: 'get'
    })
}

//搜尋與查看電子發票
export function getGroupBuyReception(query){
    return request({
        url: '/api/check_reception_issue/' + query,
        method: 'get'
    })
}

// 檢查公司統編驗證
export function checkCustomerIdentifier(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/check_company_identifier_issue',
        method: 'post',
        data
    })
}

// 檢查手機條碼
export function checkCarrierNum(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/check_barcode_issue',
        method: 'post',
        data
    })
}

// 新增發票資訊
export function insertgroupbuyreception(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/set_reception_issue',
        method: 'post',
        data
    })
}

// 作廢發票資訊
export function invalidgroupbuyreception(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/invalid_reception_issue',
        method: 'post',
        data
    })
}

// linepay退款
export function refundgroupbuylinepay(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/invalid_order_linepay_issue',
        method: 'post',
        data
    })
}

//多筆更新付款狀態及方式
export function batchgroupbuyorder(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/batch/gorder',
        method: 'post',
        data
    })
}
//多筆更新票卷審核狀態
export function batchgroupbuycoupon(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/batch/gcoupon',
        method: 'post',
        data
    })
}

//發送信箱簡訊通知
export function Notifygroupbuyreception(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/send_reception_notify',
        method: 'post',
        data
    })
}

//搜尋會員
export function getGroupBuyMember(query) {
    return request({
        url: '/api/search_groupbuy_member/' + query,
        method: 'get'
    })
}

//新增團購會員
export function insertgroupmember(data) {
    return request({
        url: '/api/insert_groupbuy_member',
        method: 'post',
        data
    })
}

//修改會員
export function updategroupmember(data) {
    return request({
        url: '/api/update_groupbuy_member',
        method: 'post',
        data
    })
}

//得知所有會員標籤
export function getgroupmember2Tag(query) {
  console.log('get_all_member2_tag', typeof (query))
  // console.log("get_member_list", query)
    return request({
      url: '/api/get_all_member2_tag/' + query,
      method: 'get'
    })
}

//得知票卷基本資訊
export function getGroupBuyCoupon(data){
    return request({
        url: '/api/get_coupon_info',
        method: 'post',
        data
    })
}
// 得知所有審核訂單資訊
export function getAllCoupon(query){
    return request({
        url: '/api/gallcoupon/'+query,
        method: 'get'
    })
}

//驗證票卷基本資訊
export function checkGroupBuyCoupon(data) {
    return request({
        url: '/api/check_coupon_info',
        method: 'post',
        data
    })
}

//搜尋會員票卷資訊(電話、信箱、姓名)
export function searchMemberCoupon(query) {
    return request({
        url: '/api/search_coupon_info/' + query,
        method: 'get'
    })
}

//得知可使用優惠卷列表
export function getGroupBuyCouponList(data) {
    return request({
        url: '/api/chosen_groupbuy_coupon',
        method: 'post',
        data
    })
}

//得知優惠卷詳細資料
export function getGroupBuyCouponDetail(query){
    return request({
        url: '/api/groupbuy_coupon/' + query,
        method: 'get'
    })
}

//新增優惠卷管理
export function insert_update_coupondiscount(data) {
    // console.log("get_member_list", query)
    return request({
        url: '/api/groupbuy_coupon',
        method: 'post',
        data
    })
}