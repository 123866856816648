import { getBug, insert_update_bug } from '@/api/bug_manager'
import { Message } from 'element-ui'

const state = {
   buglist:[],
}

const mutations = {
    SET_BUG: (state, buglist) => {
        state.buglist = buglist
    },
}

const actions = {
    // 得知所有bug回報
    getBug({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getBug(query).then(response => {
                commit('SET_BUG', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有bug回報
    insert_update_bug({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_bug(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
