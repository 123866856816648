<template>
  <div class="block">
    <div class="radio" style="margin:10px 0;">
        排序：
        <el-radio-group v-model="reverse_login_record_time">
        <el-radio :label="true">降冪</el-radio>
        <el-radio :label="false">升冪</el-radio>
        </el-radio-group>
    </div>
    <el-timeline :reverse="reverse_login_record_time">
        <!-- {{id}} -->
        <!-- {{login_record_time}} -->
      <el-timeline-item 
      v-for="(item,index) of login_record_time" 
      :key="index" 
      :timestamp="item.admin_datetime">
        <!-- <el-card>
          <h4>{{ item.title }}</h4>
          <p>{{ item.content }}</p>
        </el-card> -->
        {{item.name}} 登入系統
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLoginRecord } from '@/api/log_manager'
export default {
  name: "loginrecord",
  data() {
    return {
      login_record_time: [],
      reverse_login_record_time: false
    }
  },
  computed: {
    ...mapGetters([
      'id'
    ])
  },
  mounted() {
    this.getLoginRecord(1)
  },
  methods: {
      getLoginRecord(page) {
          var payload = ''
          payload += page + '?admin_id=' + this.id
          getLoginRecord(payload).then((resp)=>{
              this.login_record_time = resp.data.list
          })
      }
  },
}
</script>
