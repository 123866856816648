import request from '@/utils/request'

export function getDocument(query) {
    console.log("get_document",typeof(query))
    // console.log("get_question", query)
    return request({
        url: '/api/document/' + query,
        method: 'get',
    })
}
export function insert_update_document(data) {
  // console.log(data);
    return request({
        url: '/api/document',
        method: 'post',
        data
    })
}

export function getDocumentPartner(query) {
    console.log("get_document_partner", typeof (query))
    // console.log("get_question", query)
    return request({
        url: '/api/document_partner/' + query,
        method: 'get',
    })
}

export function insert_update_document_partner(data) {
    // console.log(data);
    return request({
        url: '/api/document_partner',
        method: 'post',
        data
    })
}