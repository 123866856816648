<template>
  <el-table :data="select_class_range==0?get_now_reserve_type_0:get_now_reserve_type_1" style="width: 100%;padding-top: 15px;">
    <el-table-column label="治療師/講師">
      <template slot-scope="scope">
        <el-tag>{{ scope.row.reserve_system_shop_basic_schedule_teacher }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="課程名稱" v-show="select_class_range==0">
      <template slot-scope="scope">
        {{ scope.row.reserve_system_shop_basic_schedule_class_name }}
      </template>
    </el-table-column>
    <el-table-column label="開始日期">
      <template slot-scope="scope">
        {{ scope.row.reserve_system_shop_basic_schedule_start_date }}
      </template>
    </el-table-column>
    <el-table-column label="開始時間" align="center">
      <template slot-scope="scope">
       {{scope.row.reserve_system_shop_basic_schedule_start_time }}
      </template>
    </el-table-column>
    <el-table-column label="結束時間" align="center">
      <template slot-scope="scope">
       {{scope.row.reserve_system_shop_basic_schedule_end_time }}
      </template>
    </el-table-column>
    <el-table-column align="center" label="實際預約人數">
      <template slot-scope="scope">
        <el-tag type="primary" plain>
          {{ scope.row.reserve_system_shop_basic_schedule_practice_people }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column label="排程狀態" width="100" align="center">
      <template slot-scope="scope">
        <el-tag :type="scope.row.reserve_system_shop_basic_schedule_status==1||scope.row.reserve_system_shop_basic_schedule_status==2 ? 'danger':'primary' " plain>
          {{ scope.row.reserve_system_shop_basic_schedule_status | reserve_system_shop_basic_schedule_status }}
        </el-tag>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    select_class_range:{
      type: Number,
      require: true
    },
    get_now_reserve_type_0:{
      type:  Array,
      require: true
    },
    get_now_reserve_type_1:{
       type: Array,
       require: true
    }
  },
  filters: {
    //排程狀態
    reserve_system_shop_basic_schedule_status(status){
      const reserve_system_shop_basic_schedule_status = {
        0:'可報名',
        1:'不可報名',
        2:'額滿',
      }
      return reserve_system_shop_basic_schedule_status[status]
    },
  },
  watch:{
    "select_class_range":{
        handler: function(val, oldVal) {

      },
      immediate:true
    },
  },
  data() {
    return {
      // list: null
    }
  },
  methods: {

  }
}
</script>
