import request from '@/utils/request'

//得知設定檔內層資料
export function getInnerConfig(query) {
    console.log("get_inner_config",typeof(query))
    // console.log("get_question", query)
    return request({
        url: '/api/config_name/' + query,
        method: 'get',
    })
}
//顯示所有資料庫內的tables
export function ShowDatabaseTables() {
    console.log("show_database_tables")
    // console.log("get_question", query)
    return request({
        url: '/api/show_database_tables/',
        method: 'get',
    })
}

//顯示所有資料表的schema
export function ShowDatabaseTablesField(query) {
    console.log("show_database_tables_field")
    // console.log("get_question", query)
    return request({
        url: '/api/show_database_tables_field/'+query,
        method: 'get',
    })
}

//新增、修改所有開發模組config_name資料
export function InsertUpdateConfigName(data) {
    console.log("show_database_tables_field",typeof(data))
    // console.log("get_question", query)
    return request({
        url: '/api/config_name',
        method: 'post',
        data
    })
}

//顯示所有開發模組內層資料
export function getOuterConfig(query) {
    console.log("get_outer_config")
    // console.log("get_question", query)
    return request({
        url: '/api/config_tab_name/'+query,
        method: 'get',
    })
}
//新增、修改所有開發模組內層資料
export function confirmOuterConfig(data) {
    console.log("get_outer_config")
    // console.log("get_question", query)
    return request({
        url: '/api/config_tab_name/',
        method: 'post',
        data
    })
}
