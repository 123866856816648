import { get_Driver_List_reservation_query, insert_update_updatereserve, insert_update_uploadmembercsv  } from '@/api/driver_router_manager'
import { Message } from 'element-ui'

const state = {
    driverManagerList:[],
}

const mutations = {
    SET_DRIVER_LIST_RESERVATION_QUERY: (state, driverManagerList) => {
        state.driverManagerList = driverManagerList
    }
}

const actions = {
    // 得知所有司機路線列表
    getDriverListReservationQuery({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            get_Driver_List_reservation_query(query).then(response => {
                commit('SET_DRIVER_LIST_RESERVATION_QUERY', response)
                resolve(response.items)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    InsertUpdateDriverListReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            console.log(data)
            insert_update_updatereserve(data).then(response => {
                if (response.success==true){
                    Message({
                        message: response.message,
                        type: 'success',
                        duration: 2000
                    })
                }
                else{
                    Message({
                        message: response.message,
                        type: 'error',
                        duration: 2000
                    })
                }

                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },

    InsertUpdateUploadmembercsv({ commit }, data) {
        return new Promise((resolve, reject) => {
            console.log(data)
            insert_update_uploadmembercsv(data).then(response => {
                if (response.success==true){
                    Message({
                        message: response.message,
                        type: 'success',
                        duration: 2500
                    })
                }
                else{
                    Message({
                        message: response.message,
                        type: 'error',
                        duration: 2500
                    })
                }
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
