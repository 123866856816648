import request from '@/utils/request'

export function getAdmin(query) {
  console.log('get_admin', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/admin/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/admin/' + query,
      method: 'get'
    })
  }
}
export function insert_update_admin(data) {
  // console.log(data);
  return request({
    url: '/api/admin',
    method: 'post',
    data
  })
}
export function getAdminRoles(query) {
  console.log('get_admin_roles', typeof (query))
  // console.log("get_admin_roles", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/roles/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/roles/' + query,
      method: 'get'
    })
  }
}

export function insert_update_admin_role(data) {
  // console.log(data);
  return request({
    url: '/api/roles',
    method: 'post',
    data
  })
}
// 回復密碼設定
export function reset_password_admin(data) {
  // console.log(data);
  return request({
    url: '/api/admin/reset_password',
    method: 'post',
    data
  })
}

// 清除裝置錯誤次數
export function delete_admin_device_token(data) {
  // console.log(data);
  return request({
    url: '/api/admin_device_token/delete',
    method: 'post',
    data
  })
}

// 綁定line notify
export function BindLineaccesstoken(data) {
  // console.log(data);
  return request({
    url: '/api/bind_notify',
    method: 'post',
    data
  })
}

// 取消綁定line notify
export function RevokeLineaccesstoken(data = null) {
  // console.log(data);
  return request({
    url: '/api/revoke_notify',
    method: 'post',
    data
  })
}
export function getCustomeLogin(data) {
  // console.log(data);
  return request({
    url: '/custome/login',
    method: 'post',
    data
  })
}
