var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _c(
        "el-drag-select",
        {
          staticStyle: { width: "500px" },
          attrs: { multiple: "", placeholder: "请选择" },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "30px" } },
        _vm._l(_vm.value, function(item) {
          return _c(
            "el-tag",
            { key: item, staticStyle: { "margin-right": "15px" } },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }