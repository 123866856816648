<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="main-article" element-loading-text="正在產生學員報告pdf">
    <div class="article__heading">
      <div class="article__heading__title">
        <!-- {{ article.title }} -->
        {{student_name}} 學員報告
      </div>
      <div class="article__heading__title" style="float:right;font-size:20px;">
        <!-- {{ article.title }} -->
        報告產生日期： {{report_date}}
      </div>
    </div>
    <div class="article__reporticon__title" style="float:right;font-size:20px;">
      <!-- {{ article.title }} -->
      <el-button type="primary" @click="fetchData" icon="el-icon-download">匯出 {{student_name}} 學員報告</el-button>
    </div>
    <div class="article__healthbasic__title">
      <!-- {{ article.title }} -->
      學員基本資料
    </div>
    <div class="user-profile">
      <el-form ref="HealthInfo" status-icon :model="HealthInfo" label-position="left">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="6" :lg="6" :xl="6">
              <el-form-item label="學員姓名" prop="name">
                  {{HealthInfo.name}}
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6" :lg="6" :xl="6">
              <el-form-item label="學員性別" prop="sex">
                {{HealthInfo.sex|sex}}
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="6" :xl="6">
              <el-form-item label="學員生日" prop="birthday">
                {{HealthInfo.birthday}}
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="4" :lg="6" :xl="6">
              <el-form-item label="學員身高" prop="height">
                  {{HealthInfo.height}}
              </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
              <el-form-item label="家裡電話" prop="H_Telephone" v-if="HealthInfo.H_Telephone!=null">
                   {{HealthInfo.H_Telephone}}
              </el-form-item>
              <el-form-item label="家裡電話" prop="H_Telephone" v-else>
                   無填寫
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="8" :xl="8" >
              <el-form-item label="聯絡時間" prop="contact_time" v-if="HealthInfo.contact_time!=null">
                  {{HealthInfo.contact_time}}
              </el-form-item>
              <el-form-item label="聯絡時間" prop="contact_time" v-else>
                   無填寫
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
              <el-form-item label="公司電話" prop="O_Telephone" v-if="HealthInfo.O_Telephone!=null">
                  {{HealthInfo.O_Telephone}}
              </el-form-item>
              <el-form-item label="公司電話" prop="O_Telephone" v-else>
                  無填寫
              </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :lg="8" :xl="8" >
              <el-form-item label="傳真電話" prop="FAX" v-if="HealthInfo.FAX!=null">
                  {{HealthInfo.FAX}}
              </el-form-item>
              <el-form-item label="傳真電話" prop="FAX" v-else>
                  無填寫
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
              <el-form-item label="手機" prop="Mobile">
                   {{HealthInfo.Mobile}}
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="4" :lg="4" :xl="4">
              <el-form-item label="星座" prop="constellation" v-if="HealthInfo.constellation!=null">
                  {{HealthInfo.constellation}}
              </el-form-item>
              <el-form-item label="星座" prop="constellation" v-else>
                  無填寫
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="4" :lg="4" :xl="4">
              <el-form-item label="血型" prop="blood" v-if="HealthInfo.blood!=''">
                  {{HealthInfo.blood}}
              </el-form-item>
              <el-form-item label="血型" prop="blood" v-else>
                  無填寫
              </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
              <el-form-item label="配偶姓名" prop="spouse_name" v-if="HealthInfo.spouse_name!=''">
                   {{HealthInfo.spouse_name}}
              </el-form-item>
              <el-form-item label="配偶姓名" prop="spouse_name" v-else>
                  無填寫
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
              <el-form-item label="結婚紀念日" prop="anniversary" v-if="HealthInfo.anniversary!=null">
                  {{HealthInfo.anniversary}}
              </el-form-item>
              <el-form-item label="結婚紀念日" prop="anniversary" v-else>
                  無填寫
              </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
              <el-form-item label="課程狀態" prop="class_level">
                  {{HealthInfo.class_level|class_level}}
              </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="article__healthbasic__title">
      <!-- {{ article.title }} -->
      學員所有量測數據
      <!-- {{HealthMeatureInfo}} -->
    </div>
    <div class="user_profile">
      <el-row :gutter="20">
        <el-form ref="HealthMeatureInfo" status-icon label-position="left" v-for="(item,index) in HealthMeatureInfo" :key="index">
          <el-divider>量測數據開始</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="量測時間">
                    {{item.measuretime}}
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="體重">
                    {{item.weight}} kg
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="體脂">
                    {{item.bodyfat}} %
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="肌肉量">
                    {{item.muscle}} kg
                </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="內脂">
                    {{item.lactose}} %
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="基礎代謝">
                    {{item.BMR}}
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="體齡">
                    {{item.bodyfat}} 歲
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="骨量">
                    {{item.mass}} %
                </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="胸圍（腋下）">
                    {{item.chest_underarm}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="胸圍（乳頭）">
                    {{item.chest_nipples}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="胃圍">
                    {{item.stomach}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="肚臍下三指">
                    {{item.navel_down}} cm
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="肚臍上三指">
                    {{item.navel_up}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="臀圍">
                    {{item.hip}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="大腿（右）">
                    {{item.thigh_r}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="大腿（左）">
                    {{item.thigh_l}} cm
                </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="小腿（右）">
                    {{item.calf_r}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="小腿（左）">
                    {{item.calf_l}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="手臂（右）">
                    {{item.arm_r}} cm
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="手臂（左）">
                    {{item.arm_l}} cm
                </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                <el-form-item label="學員回饋">
                    {{item.member_feedback}}
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                <el-form-item label="教練回饋">
                    {{item.coach_feedback}}
                </el-form-item>
            </el-col>
          </el-row>
          <el-divider>量測數據結束</el-divider>

        </el-form>
      </el-row>
    </div>
    <div class="article__healthbasic__title">
      <!-- {{ article.title }} -->
      學員使用前後照片
      <!-- {{HealthMeatureInfo}} -->
    </div>
    <div class="user_profile">
      <el-row :gutter="20">
        <el-form ref="HealthMeatureInfo" status-icon label-position="left" v-for="(item,index) in HealthPhotoInfo" :key="index">
          <el-divider>圖片開始</el-divider>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="圖片標記">
                    {{item.note_name}}
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="圖片">
                  <el-image :src="upload_path+item.upload_image" style="max-width:350px;">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="備註內容">
                  {{item.mark}}
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item label="建立日期">
                    {{item.createtime}}
                </el-form-item>
            </el-col>
          </el-row>
          <el-divider>圖片結束</el-divider>
        </el-form>
      </el-row>
    </div>
    <!-- <div style="color: #ccc;">
      This article is from Evan You on <a target="_blank" href="https://medium.com/the-vue-point/plans-for-the-next-iteration-of-vue-js-777ffea6fabf">medium</a>
    </div>
    <div ref="content" class="node-article-content" v-html="article.content" /> -->
  </div>
</template>

<script>
import moment from 'moment'
import { getSingleStudent,getMeatureStudent,getPhotoStudent } from '@/api/health_manager'
export default {
  data() {
    return {
      article: '',
      fullscreenLoading: true,
      //報告產生日期
      report_date:'',
      //學員姓名
      student_name:'',
      payload:'',
      //學員基本資料
      HealthInfo:[],
      //學員所有量測資料
      HealthMeatureInfo:[],
      //學員所有照片
      HealthPhotoInfo: [],
      upload_path:''
    }
  },
  filters:{
    //性別
    sex(status) {
      const sex = {
        1: '男',
        2: '女'
      }
      return sex[status]
    },
    //學員權限
    class_level(status) {
      const class_level = {
        null: '未填寫',
        1: '卡客',
        2: '天使計畫'
      }
      return class_level[status]
    },
  },
  async mounted() {
    this.report_date = moment(new Date()).format('YYYY/MM/DD')
    // 圖片網址位置
    this.upload_path = process.env.VUE_APP_BASE_API_UPLOAD
    console.log("this.$route.params.partner_id",this.$route.params.partner_id)
    console.log("this.$route.params.mid",this.$route.params.mid)
    await this.gethealthinfo()
    await this.gethealthMeatureinfo()
    await this.gethealthPhotoinfo()
    await this.fetchData()
  },
  methods: {
    //會員基本資料
    async gethealthinfo(){
      this.HealthInfo = []
      this.payload = '1?partner_id='+this.$route.params.partner_id+'&mid='+this.$route.params.mid
      await getSingleStudent(this.payload).then(resp => {
        this.HealthInfo = resp.data.list[0]
        this.student_name = this.HealthInfo.name
      })
    },
    async gethealthMeatureinfo(){
      this.HealthMeatureInfo = []
      this.payload = 'all?source=2&partner_id='+this.$route.params.partner_id+'&mid='+this.$route.params.mid
      await getMeatureStudent(this.payload).then(resp => {
        console.log("1229",resp.data.list)
        this.HealthMeatureInfo = resp.data.list
      })
    },
    async gethealthPhotoinfo(){
      this.HealthPhotoInfo = []
      this.payload = {
          source: '2',
          partner_id: this.$route.params.partner_id,
          mid :this.$route.params.mid
      }
      await getPhotoStudent(this.payload).then(resp => {
        this.HealthPhotoInfo = resp.data.list
      })
    },
    fetchData() {
      import('./content.js').then(data => {
        // const { title } = data.default
        document.title = this.student_name +' 學員報告'
        this.article = data.default
        setTimeout(() => {
          this.fullscreenLoading = false
          this.$nextTick(() => {
            window.print()
          })
        }, 500)
      })
    }
  }
}
</script>

<style lang="scss">
@mixin clearfix {
  &:before {
    display: table;
    content: '';
    clear: both;
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}
@media print {
  .article__reporticon__title {
    display: none;
  }
}

.main-article {
  padding: 20px;
  margin: 0 auto;
  display: block;
  width: 740px;
  background: #fff;
}

.article__heading {
  position: relative;
  padding: 0 0 20px;
  overflow: hidden;
}

.article__heading__title {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: #333;
  overflow: hidden;
}
.article__reporticon__title,.article__healthbasic__title {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 25px;
  line-height: 48px;
  font-weight: 600;
  color: #333;
  overflow: hidden;
}

.node-article-content {
  margin: 20px 0 0;
  @include clearfix;
  font-size: 16px;
  color: #333;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: 30px;
  font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman", Times, serif;

  &> :last-child {
    margin-bottom: 0;
  }

  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -.003em;

  }

  ul {
    margin-bottom: 30px;
  }

  li {
    --x-height-multiplier: 0.375;
    --baseline-multiplier: 0.17;

    letter-spacing: .01rem;
    font-weight: 400;
    font-style: normal;
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -.003em;
    margin-left: 30px;
    margin-bottom: 14px;
  }

  a {
    text-decoration: none;
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, rgba(0, 0, 0, .84) 100%, rgba(0, 0, 0, 0) 0);
    background-size: 1px 1px;
    background-position: 0 calc(1em + 1px);
    padding: 0 6px;
  }

  code {
    background: rgba(0, 0, 0, .05);
    padding: 3px 4px;
    margin: 0 2px;
    font-size: 16px;
    display: inline-block;
  }

  img {
    border: 0;
  }

  /* 解决 IE6-7 图片缩放锯齿问题 */
  img {
    -ms-interpolation-mode: bicubic;
  }

  blockquote {
    --x-height-multiplier: 0.375;
    --baseline-multiplier: 0.17;
    font-family: medium-content-serif-font, Georgia, Cambria, "Times New Roman", Times, serif;
    letter-spacing: .01rem;
    font-weight: 400;
    font-style: italic;
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -.003em;
    border-left: 3px solid rgba(0, 0, 0, .84);
    padding-left: 20px;
    margin-left: -23px;
    padding-bottom: 2px;
  }

  a {
    text-decoration: none;
  }

  h2,
  h3,
  h4 {
    font-size: 34px;
    line-height: 1.15;
    letter-spacing: -.015em;
    margin: 53px 0 0;
  }

  h4 {
    font-size: 26px;
  }
  .user-profile {
    .user-name {
      font-weight: bold;
    }

    .box-center {
      padding-top: 10px;
    }

    .user-role {
      padding-top: 10px;
      font-weight: 400;
      font-size: 14px;
    }

    .box-social {
      padding-top: 30px;

      .el-table {
        border-top: 1px solid #dfe6ec;
      }
    }

    .user-follow {
      padding-top: 20px;
    }
  }
}
</style>
