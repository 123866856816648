import request from '@/utils/request'


//得知會計管理
export function getAppointReceptionList(query) {
  console.log('get_reception_record', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/reception_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/reception_record/' + query,
      method: 'get'
    })
  }
}

//新增、修改會計管理
export function postAppointReceptionList(data) {
  console.log('insert_update_reception_record_list', typeof (data))
    return request({
      url: '/api/insert_update_reception_record',
      method: 'post',
      data
    })
}
