import { getHealth, getHealthCategory, insert_update_health, insert_update_health_category,edit_delete_health_photo } from '@/api/reservationsystemappointhealth'
import { Message } from 'element-ui'

const state = {
   healthlist:[],
   healthcategorylist: []
}

const mutations = {
    SET_HEALTH: (state, healthlist) => {
        state.healthlist = healthlist
    },
    SET_HEALTH_CATEGORY: (state, healthcategorylist) => {
        state.healthcategorylist = healthcategorylist
    }
}

const actions = {
    // 得知所有衛教
    getHealth({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getHealth(query).then(response => {
                commit('SET_HEALTH', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有衛教
    getHealthCategory({ commit }, page) {
        return new Promise((resolve, reject) => {
            getHealthCategory(page).then(response => {
                // console.log("test", response)
                commit('SET_HEALTH_CATEGORY', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有衛教
    insert_update_health({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_health(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    //新增及修改所有衛教
    insert_update_health_category({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_health_category(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    //修改、刪除衛教圖片資料
    edit_delete_health_photo({ commit }, data) {
        return new Promise((resolve, reject) => {
            edit_delete_health_photo(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
