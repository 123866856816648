import request from '@/utils/request'

export function getBug(query) {
    console.log("get_bug",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/bug_manager_record/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/bug_manager_record/' + query,
            method: 'get',
        })
    }
}
export function insert_update_bug(data) {
  // console.log(data);
    return request({
        url: '/api/bug_manager_record',
        method: 'post',
        data
    })
}