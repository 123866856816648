import request from '@/utils/request'

// 得知登入時間
export function getLoginRecord(query) {
    console.log("get_admin_login_record",typeof(query))
    // console.log("get_admin", query)
    if(typeof(query)=="number") {
        return request({
            url: '/api/admin_login_record/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            // url: '/api/admin_login_record/' + '1' + '?' + query,
            url: '/api/admin_login_record/' + query,
            method: 'get',
        })
    }
}

// 得知通知
export function getNotifyRecord(query) {
    console.log("get_admin_notify_record", typeof (query))
    // console.log("get_admin", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/admin_notify_record/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/admin_notify_record/' + '1' + '?' + query,
            method: 'get',
        })
    }
}
// 得知操作記錄
export function getLogRecord(query) {
    console.log("get_log_record", typeof (query))
    // console.log("get_admin", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/all_log/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/all_log/' + query,
            method: 'get',
        })
    }
}