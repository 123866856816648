var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "components-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "editor-container" },
      [
        _c("dropzone", {
          attrs: { id: "myVueDropzone", url: "https://httpbin.org/post" },
          on: {
            "dropzone-removedFile": _vm.dropzoneR,
            "dropzone-success": _vm.dropzoneS
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v(" Based on "),
      _c(
        "a",
        {
          staticClass: "link-type",
          attrs: { href: "https://github.com/rowanwins/vue-dropzone" }
        },
        [_vm._v(" dropzone ")]
      ),
      _vm._v(
        ". Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }