import request from '@/utils/request'


//得知預約基本資料
export function getAppointShopList(query) {
  console.log('get_appoint_shop_list', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/appoint_shop_basic_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/appoint_shop_basic_record/' + query,
      method: 'get'
    })
  }
}

//新增、修改預約基本資料
export function postAppointShopList(data) {
  console.log('post_appoint_shop_list', typeof (data))
    return request({
      url: '/api/appoint_shop_basic_record',
      method: 'post',
      data
    })
}

//得知常用子句資料
export function getWordCategory(query) {
  console.log('get_word_category_list', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/reserve_system_record_category/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/reserve_system_record_category/' + query,
      method: 'get'
    })
  }
}

//新增、修改常用子句資料
export function postWordCategory(data) {
  console.log('post_word_category_list', typeof (data))
    return request({
      url: '/api/reserve_system_record_category',
      method: 'post',
      data
    })
}

//刪除常用子句資料
export function deleteWordCategory(data) {
  console.log('delete_word_category_list', typeof (data))
    return request({
      url: '/api/delete_reserve_system_record_category',
      method: 'post',
      data
    })
}
