import { login, loginotp, logout, getInfo, getUserMenu } from '@/api/user'
import { Layout } from '@/layout'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  email:'',
  avatar: '',
  phone: '',
  introduction: '',
  roles: null,
  partner_id: null,
  partner_avatar: '',
  partner_name: '',
  id: null,
  usermenu: [],
  username: '',
  status: null,
  //二次驗證模式
  validator_mode:null,
  //二次驗證開關
  validator_mode_status:null,
  push_channel_token: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER_MENU: (state, menu) => {
    state.usermenu = menu
  },
  SET_PARTNER_ID: (state, partner_id) => {
    state.partner_id = partner_id
  },
  SET_PARTNER_AVATAR: (state, partner_avatar) => {
    state.partner_avatar = partner_avatar
  },
  SET_PARTNER_NAME: (state, partner_name) => {
    state.partner_name = partner_name
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_STATUS: (state, status) => {
    state.status = status
  },
  SET_VALIDATOR_MODE: (state, validator_mode) => {
    state.validator_mode = validator_mode
  },
  SET_VALIDATOR_MODE_STATUS: (state, validator_mode_status) => {
    state.validator_mode_status = validator_mode_status
  },
  SET_PUSH_CHANNEL_TOKEN: (state, push_channel_token)=> {
    state.push_channel_token = push_channel_token
  },
  SET_SPECIAL_DAY: (state, special_day)=> {
  state.special_day = special_day
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, partner_id, validator, device_token, recaptcha_token } = userInfo
    return new Promise((resolve, reject) => {
      //沒有合作夥伴
      if (partner_id==""){
        var userinfo = { username: username.trim(), password: password, validator: validator, device_token: device_token, recaptcha_token: recaptcha_token }
      }
      //有合作夥伴
      else{
        userinfo = { username: username.trim(), password: password, partner_id: partner_id, validator: validator, device_token: device_token, recaptcha_token: recaptcha_token }
      }
      login(userinfo).then(response => {
        commit('SET_TOKEN', response.token)
        setToken(response.token)
        Message({
          message: response.message,
          type: response.success == false ? 'error' : 'success',
          duration: 3500
        })
        resolve(response)
      }).catch(error => {
        console.log("error", error)
        reject(error)
      })
    })
  },

  // user loginotp
  loginotp({ commit }, userInfo) {
    const { username, password, partner_id, recaptcha_token } = userInfo
    return new Promise((resolve, reject) => {
      // 沒有合作夥伴
      if (partner_id == "") {
        var userinfo = { username: username.trim(), password: password, recaptcha_token: recaptcha_token }
      }
      // 有合作夥伴
      else {
        userinfo = { username: username.trim(), password: password, partner_id: partner_id, recaptcha_token: recaptcha_token }
      }
      loginotp(userinfo).then(response => {
        Message({
          message: response.message,
          type: response.success == false? 'error':'success',
          duration: 3500
        })
        resolve(response)
      }).catch(error => {
        console.log("error", error)
        reject(error)
      })
    })
  },



  // get user info
  async getInfo({ commit,router}) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        // roles must be a non-empty array
        console.log("getinfo",response)
        if (!response.data.roles || response.data.roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }
        commit('SET_ID', response.data.id)
        commit('SET_ROLES', response.data.roles)
        commit('SET_NAME', response.data.name)
        commit('SET_EMAIL', response.data.email)
        commit('SET_AVATAR', response.data.avatar)
        commit('SET_PHONE', response.data.phone)
        commit('SET_INTRODUCTION', response.data.introduction)
        commit('SET_PARTNER_ID', response.data.partner_id)
        commit('SET_PARTNER_AVATAR', response.data.partner_avatar)
        commit('SET_PARTNER_NAME', response.data.partner_name)
        commit('SET_USERNAME', response.data.username)
        commit('SET_VALIDATOR_MODE', response.data.validator_mode)
        commit('SET_VALIDATOR_MODE_STATUS', response.data.validator_mode_status)
        commit('SET_STATUS', response.data.status)
        commit('SET_PUSH_CHANNEL_TOKEN', response.data.push_channel_token)
        commit('SET_SPECIAL_DAY', response.data.special_day)
        resolve(response.data)
      }).catch(error => {
        console.log("error",error)
        reject()
      })
    })
  },

  // get user menu
  // getUserMenu() {
  //   return new Promise((resolve, reject) => {
  //   getUserMenu().then(response => {
  //     console.log(response.data.list);
  //     let arr = []
  //     response.data.list.foreach((item)=>{
  //       let obj = {}
  //       obj.children = []
  //       ob.path = item.premission_route
  //       obj.componemt = Layout
  //       obj.children.push({
  //         name: item.premission_meta_title,
  //         path: item.premission_route,
  //         component: (reslove) => () => import('@/views/' + item.premission_route+'/index'),
  //         meta:{
  //           title: item.premission_name,
  //           icon: item.premission_meta_icon,
  //           affix: item.premission_meta_affix
  //         }
  //       })
  //     arr.push(obj)
  //     })
  //     arr.push({path: '*', redirect: '/404', hidden: true })
  //     resolve(arr)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', null)
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })
        Message({
          message: "登出成功",
          type: 'success',
          duration: 2000
        })
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
