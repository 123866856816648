<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    },
    get_practice_reserve_date:{
      type: Array,
      required: true
    },
    select_line_type:{
      type: String,
      default: 'shopping',
      required: true,
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        console.log(val)
        this.setOptions(val)
      }
      // handler: function(val, oldVal) {
      //   console.log("51: ",val)
      //   this.setOptions(val)
      // },
      // immediate: true
    },
    get_practice_reserve_date: {
      deep: true,
      handler(val) {
        console.log("54:",val)
        this.initChart()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions(val) {
      console.log("87:",val)
      console.log("88:",this.select_line_type)
      // if(val.hasOwnProperty('shopping')){
      //顯示實際名稱
      var select_line_name =''
      if(this.select_line_type=='shopping'){
        select_line_name = '實際預約數'
      }
      if(this.select_line_type=='money'){
        select_line_name = '實際盈餘'
      }
      if(this.select_line_type=='user'){
        select_line_name = '實際會員數'
      }
      this.chart.setOption({
        xAxis: {
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          data: this.get_practice_reserve_date,
          boundaryGap: true,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        yAxis: {
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        legend: {
          // data: ['預估', '實際'],
          data: [select_line_name]
        },
        series: [
        //   {
        //   name: '預估', itemStyle: {
        //     normal: {
        //       color: '#FF005A',
        //       lineStyle: {
        //         color: '#FF005A',
        //         width: 2
        //       }
        //     }
        //   },
        //   smooth: true,
        //   type: 'line',
        //   data: expectedData,
        //   animationDuration: 2800,
        //   animationEasing: 'cubicInOut'
        // },
        {
          name: select_line_name,
          smooth: true,
          type: 'line',
          itemStyle: {
            normal: {
              // color: '#3888fa',
              color: '#FF005A',
              lineStyle: {
                // color: '#3888fa',
                color: '#FF005A',
                width: 2.5
              },
              areaStyle: {
                color: '#f3f8ff'
              }
            }
          },
          data: val[this.select_line_type],
          animationDuration: 2900,
          animationEasing: 'quadraticOut'
        }
        ]
      })
      // }
      // else{
      // this.chart.setOption({
      //   xAxis: {
      //     // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      //     data: this.get_practice_reserve_date,
      //     boundaryGap: false,
      //     axisTick: {
      //       show: false
      //     }
      //   },
      //   grid: {
      //     left: 10,
      //     right: 10,
      //     bottom: 20,
      //     top: 30,
      //     containLabel: true
      //   },
      //   tooltip: {
      //     trigger: 'axis',
      //     axisPointer: {
      //       type: 'cross'
      //     },
      //     padding: [5, 10]
      //   },
      //   yAxis: {
      //     axisTick: {
      //       show: false
      //     }
      //   },
      //   legend: {
      //     // data: ['預估', '實際'],
      //     data: ['實際']
      //   },
      //   series: [
      //   //   {
      //   //   name: '預估', itemStyle: {
      //   //     normal: {
      //   //       color: '#FF005A',
      //   //       lineStyle: {
      //   //         color: '#FF005A',
      //   //         width: 2
      //   //       }
      //   //     }
      //   //   },
      //   //   smooth: true,
      //   //   type: 'line',
      //   //   data: expectedData,
      //   //   animationDuration: 2800,
      //   //   animationEasing: 'cubicInOut'
      //   // },
      //   {
      //     name: '實際',
      //     smooth: true,
      //     type: 'line',
      //     itemStyle: {
      //       normal: {
      //         color: '#3888fa',
      //         lineStyle: {
      //           color: '#3888fa',
      //           width: 2
      //         },
      //         areaStyle: {
      //           color: '#f3f8ff'
      //         }
      //       }
      //     },
      //     data: val,
      //     animationDuration: 2800,
      //     animationEasing: 'quadraticOut'
      //   }
      //   ]
      // })
      // }
    }
  }
}
</script>
