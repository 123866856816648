import request from '@/utils/request'

//儀表板(預約、盈餘、會員包括折線圖)
export function get_Appoint_Dashboard_1(query) {
    console.log("get_Appoint_Dashboard_1",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/appoint_dashboard_1/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/appoint_dashboard_1/' + query,
            method: 'get',
        })
    }
}

//儀表板(熱銷程度排名、即將來到預約)
export function get_Appoint_Dashboard_2(query) {
    console.log("get_Appoint_Dashboard_2",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/appoint_dashboard_2/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/appoint_dashboard_2/' + query,
            method: 'get',
        })
    }
}