import request from '@/utils/request'

export function getShop(query) {
    console.log("get_shop",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/shop/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/shop/' + query,
            method: 'get',
        })
    }
}
export function getShopCategory(page) {
    return request({
        url: '/api/shop_category/' + page,
        method: 'get',
    })
}
export function insert_update_shop(data) {
  // console.log(data);
    return request({
        url: '/api/shop',
        method: 'post',
        data
    })
}
export function insert_update_shop_category(data) {
    // console.log(data);
    return request({
        url: '/api/shop_category',
        method: 'post',
        data
    })
}

export function getShopstore(query) {
    console.log("getShopstore", typeof (query))
    // console.log("get_question", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/shopstore/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/shopstore/' + query,
            method: 'get',
        })
    }
}
export function insert_update_shopstore(data) {
    // console.log(data);
    return request({
        url: '/api/shopstore',
        method: 'post',
        data
    })
}

export function getOrder(query) {
    console.log("get_order", typeof (query))
    // console.log("get_question", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/order/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/order/' + query,
            method: 'get',
        })
    }
}
export function insert_update_order(data) {
    // console.log(data);
    return request({
        url: '/api/order',
        method: 'post',
        data
    })
}

export function getAllMember(query) {
    console.log("get_all_member", typeof (query))
    // console.log("get_question", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/all_member/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/all_member/' + query,
            method: 'get',
        })
    }
}