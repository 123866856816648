<template>
  <div />
</template>

<script>
export default {
  created() {
    this.b = b // eslint-disable-line
  }
}
</script>
