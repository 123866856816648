var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _vm._m(0),
      _c(
        "split-pane",
        { attrs: { split: "vertical" }, on: { resize: _vm.resize } },
        [
          _c("template", { slot: "paneL" }, [
            _c("div", { staticClass: "left-container" })
          ]),
          _c(
            "template",
            { slot: "paneR" },
            [
              _c(
                "split-pane",
                { attrs: { split: "horizontal" } },
                [
                  _c("template", { slot: "paneL" }, [
                    _c("div", { staticClass: "top-container" })
                  ]),
                  _c("template", { slot: "paneR" }, [
                    _c("div", { staticClass: "bottom-container" })
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _c("strong", [_vm._v("SplitPane")]),
      _vm._v(" If you've used "),
      _c("a", { attrs: { href: "https://codepen.io/", target: "_blank" } }, [
        _vm._v(" codepen")
      ]),
      _vm._v(", "),
      _c("a", { attrs: { href: "https://jsfiddle.net/", target: "_blank" } }, [
        _vm._v(" jsfiddle ")
      ]),
      _vm._v("will not be unfamiliar. "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/PanJiaChen/vue-split-pane",
            target: "_blank"
          }
        },
        [_vm._v(" Github repository")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }