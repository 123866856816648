var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mixin-components-container" },
    [
      _c(
        "el-row",
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header"
              },
              [_c("span", [_vm._v("Buttons")])]
            ),
            _c(
              "div",
              { staticStyle: { "margin-bottom": "50px" } },
              [
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 4 } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pan-btn blue-btn",
                        attrs: { to: "/documentation/index" }
                      },
                      [_vm._v(" Documentation ")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 4 } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pan-btn light-blue-btn",
                        attrs: { to: "/icon/index" }
                      },
                      [_vm._v(" Icons ")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 4 } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pan-btn pink-btn",
                        attrs: { to: "/excel/export-excel" }
                      },
                      [_vm._v(" Excel ")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 4 } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pan-btn green-btn",
                        attrs: { to: "/table/complex-table" }
                      },
                      [_vm._v(" Table ")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 4 } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pan-btn tiffany-btn",
                        attrs: { to: "/example/create" }
                      },
                      [_vm._v(" Form ")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 4 } },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "pan-btn yellow-btn",
                        attrs: { to: "/theme/index" }
                      },
                      [_vm._v(" Theme ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "50px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("Material Design 的input")])]
                ),
                _c(
                  "div",
                  { staticStyle: { height: "100px" } },
                  [
                    _c(
                      "el-form",
                      { attrs: { model: _vm.demo, rules: _vm.demoRules } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "title" } },
                          [
                            _c(
                              "md-input",
                              {
                                attrs: {
                                  icon: "el-icon-search",
                                  name: "title",
                                  placeholder: "输入标题"
                                },
                                model: {
                                  value: _vm.demo.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.demo, "title", $$v)
                                  },
                                  expression: "demo.title"
                                }
                              },
                              [_vm._v(" 标题 ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("图片hover效果")])]
                ),
                _c(
                  "div",
                  { staticClass: "component-item" },
                  [
                    _c(
                      "pan-thumb",
                      {
                        attrs: {
                          width: "100px",
                          height: "100px",
                          image:
                            "https://wpimg.wallstcn.com/577965b9-bb9e-4e02-9f0c-095b41417191"
                        }
                      },
                      [_vm._v(" vue-element-admin ")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("水波纹 waves v-directive")])]
                ),
                _c(
                  "div",
                  { staticClass: "component-item" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        attrs: { type: "primary" }
                      },
                      [_vm._v(" 水波纹效果 ")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("hover text")])]
                ),
                _c(
                  "div",
                  { staticClass: "component-item" },
                  [
                    _c("mallki", {
                      attrs: {
                        "class-name": "mallki-text",
                        text: "vue-element-admin"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "50px" }, attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("Share")])]
                ),
                _c(
                  "div",
                  {
                    staticClass: "component-item",
                    staticStyle: { height: "420px" }
                  },
                  [
                    _c("dropdown-menu", {
                      staticStyle: { margin: "0 auto" },
                      attrs: { items: _vm.articleList, title: "系列文章" }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }