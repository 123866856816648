var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container board" },
    [
      _c("Kanban", {
        key: 1,
        staticClass: "kanban todo",
        attrs: { list: _vm.list1, group: _vm.group, "header-text": "Todo" }
      }),
      _c("Kanban", {
        key: 2,
        staticClass: "kanban working",
        attrs: { list: _vm.list2, group: _vm.group, "header-text": "Working" }
      }),
      _c("Kanban", {
        key: 3,
        staticClass: "kanban done",
        attrs: { list: _vm.list3, group: _vm.group, "header-text": "Done" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }