import { render, staticRenderFns } from "./UserCard.vue?vue&type=template&id=218e8838&scoped=true&"
import script from "./UserCard.vue?vue&type=script&lang=js&"
export * from "./UserCard.vue?vue&type=script&lang=js&"
import style0 from "./UserCard.vue?vue&type=style&index=0&id=218e8838&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218e8838",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/andy1229/專案/vue_後端網頁介面參考/研訊/shopstore_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('218e8838')) {
      api.createRecord('218e8838', component.options)
    } else {
      api.reload('218e8838', component.options)
    }
    module.hot.accept("./UserCard.vue?vue&type=template&id=218e8838&scoped=true&", function () {
      api.rerender('218e8838', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/components/UserCard.vue"
export default component.exports